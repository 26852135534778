//React, React Router, Formik
import React, {useState} from 'react';
import AccordContent from "@modules/landing-page/CourseCurriculum/AccordContent";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import AccordPanel from "@ui/LandingPage/AccordPanel/AccordPanel";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before": {
                content: '"\\1F973"'
            }
        }
    },
    body: {
        color: theme.palette.landingPage.font2,
        '& li': {
            ...theme.typography.landingPage.body,
            lineHeight: '35px',
        },
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            paddingLeft: theme.spacing(10),
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
    wrapper1: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6)
        }
    },
    wrapper2: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(10)
        }
    }
}));

const CourseCurriculum = () => {

    const classes = useStyles();

    const [expandedAccord, setExpandedAccord] = useState(false);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        console.log('panel: ', panel);
        // console.log('isExpanded: ', isExpanded);
        setExpandedAccord(isExpanded ? panel : false);
    };

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span/></strong> The Program  Curriculum
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>

                <Typography>
                    These are the topics and exercises we will be covering with pre-recorded videos. Then weekly for a whole year we will do lives on the same and new topics related to content creation. We will discuss and clear your doubts related to all the topics in the live.
                </Typography>

            </Grid>

            <Grid item xs={12} style={{maxWidth: 940, margin: 'auto'}}>
                <div className={classes.wrapper1}>
                    <div className={classes.wrapper2}>
                        {
                            AccordContent.map(row => (
                                <AccordPanel
                                    key={row.id}
                                    row={row}
                                    expandedAccord={expandedAccord}
                                    expandedAccordionHandler={handleChangeAccordion}/>
                            ))
                        }
                    </div>
                </div>
            </Grid>

        </Grid>
    );
};

export default CourseCurriculum;
