//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import Link from "next/link";
import Button from "@material-ui/core/Button";
import ActionReminder from "@modules/landing-page/ActionReminder/ActionReminder";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        // background: 'white',
        padding: theme.spacing(6.25, 3.75, 15),
        "& > div": {
            padding: theme.spacing(0, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(6.25)
    },
    body: {
        ...theme.typography.landingPage.body,
        marginBottom: theme.spacing(6.25)
    },
    youtubeWrapper: {
        "& iframe": {
            height: 260,
            borderRadius: 20,
            width: '100%'
        }
    }
}));

const LearnGrowYoutube = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>

            {/*Content*/}
            <Grid item xs={12} md={6} container direction="column">
                <Grid item>
                    <Typography className={classes.title}>📚 Learn How To Make Money With Social Media</Typography>
                </Grid>

                <Grid item>
                    <Typography className={classes.body}>
                        Grow your Social Media from 0 to 100,000+ followers and transform it into a high-income money
                        printing machine without even quitting your job or studies. 😉
                    </Typography>
                </Grid>

                <Grid item>
                    <ActionReminder positionedAtRoot={false}/>
                </Grid>
            </Grid>

            {/*Video*/}
            <Grid item xs={12} md={6} className={classes.youtubeWrapper}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/zbItlczMNlE"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </Grid>
        </Grid>
    );
};

export default LearnGrowYoutube;
