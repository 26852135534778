//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Divider, Grid, useMediaQuery} from "@material-ui/core";
import Header from "@modules/landing-page/Header/Header";
import LearnGrowYoutube from "@modules/landing-page/LearnGrowYoutube/LearnGrowYoutube";
import EnrolmentStatus from "@modules/landing-page/EnrolmentStatus/EnrolmentStatus";
import MoneyMaking from "@modules/landing-page/MoneyMaking/MoneyMaking";
import SecretSubscribers from "@modules/landing-page/SecretSubscribers/SecretSubscribers";
import ChallengesOfYoutuber from "@modules/landing-page/ChallengesOfYoutuber/ChallengesOfYoutuber";
import PartTimeYoutuber from "@modules/landing-page/PartTimeYoutuber/PartTimeYoutuber";
import VideoMarketing from "@modules/landing-page/VideoMarketing/VideoMarketing";
import ActionReminder from "@modules/landing-page/ActionReminder/ActionReminder";
import CourseOutcome from "@modules/landing-page/CourseOutcome/CourseOutcome";
import CourseContent from "@modules/landing-page/CourseContent/CourseContent";
import CourseFor from "@modules/landing-page/CourseFor/CourseFor";
import CourseNotFor from "@modules/landing-page/CourseNotFor/CourseNotFor";
import CourseCurriculum from "@modules/landing-page/CourseCurriculum/CourseCurriculum";
import TimeTable from "@modules/landing-page/TimeTable/TimeTable";
import ExtraPerks from "@modules/landing-page/ExtraPerks/ExtraPerks";
import ExclusiveBenefit from "@modules/landing-page/ExclusiveBenefit/ExclusiveBenefit";
import Testimonial3 from "@ui/LandingPage/Testimonial3/Testimonial3";
import MoneyBackPromise from "@modules/landing-page/MoneyBackPromise/MoneyBackPromise";
import FAQ from "@modules/landing-page/FAQ/FAQ";
import Scholarship from "@modules/landing-page/Scholarship/Scholarship";
import Contact from "@modules/landing-page/Contact/Contact";
import Footer from "@modules/landing-page/Footer/Footer";
import ExtraContent from "@modules/landing-page/ExtraContent/ExtraContent";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.landingPage.font,
        background: theme.palette.landingPage.body,
        overflow: 'hidden',
    },
    whitePageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: 'white',
        },
        "& > div": {
            background: 'white',
        },
    },
    greyPageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: theme.palette.landingPage.body2
        },
        "& > div": {
            background: theme.palette.landingPage.body2,

        },
    },
    darkPageWrapper: {
        // paddingTop: theme.spacing(15),
        [theme.breakpoints.up('md')]: {
            background: theme.palette.landingPage.body3
        },
        "& > div": {
            background: theme.palette.landingPage.body3,

        },
    },
    primaryPageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: theme.palette.landingPage.primary,
        },
        "& > div": {
            background: theme.palette.landingPage.primary,
        },
    },
    darkestPageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: theme.palette.landingPage.body5,
        },
        "& > div": {
            background: theme.palette.landingPage.body5,
        },
    },
    section: {
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 540
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1170,
        },
    },
    divider: {
        background: 'white',
        padding: theme.spacing(4, 0),
    }
}));

const LandingPage = () => {

    const classes = useStyles();
    const theme = useTheme();

    const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div className={classes.root}>
            <div className={classes.whitePageWrapper} >

                <div className={classes.section} style={{maxWidth: 970}}>
                    <Header />
                </div>

                <div className={classes.divider}/>

                <div className={classes.section} style={{maxWidth: 970}}>
                    <LearnGrowYoutube />
                </div>

                <div className={classes.section} style={{maxWidth: 970}}>
                    <EnrolmentStatus />
                </div>
            </div>

            <div className={classes.greyPageWrapper} style={{maxWidth: 970, margin: 'auto'}}>

                <div className={classes.section} style={{paddingBlock: '60px'}}>
                    <MoneyMaking />
                </div>

                <div className={classes.section}>
                    <SecretSubscribers />
                </div>

            </div>

            <div className={classes.whitePageWrapper}>

                <div className={classes.section} style={{paddingTop: 50, maxWidth: 970}}>
                    <ChallengesOfYoutuber />
                </div>

            </div>

            <div className={classes.greyPageWrapper}>

                <div className={classes.section} style={{paddingBottom: 50, maxWidth: 970}}>
                    <PartTimeYoutuber />
                </div>

                {/*Removed as per content*/}
                {/*<div className={classes.section} style={{marginTop: matchesMdUp ? 50 : 0}}>
                    <VideoMarketing />
                </div>*/}

                <div className={classes.section} style={{marginBottom: 60}}>
                    <ActionReminder positionedAtRoot={true}/>
                </div>

            </div>

            <div className={classes.darkPageWrapper}>

                <div className={classes.section} style={{paddingBottom: 60, maxWidth: 970}}>
                    <CourseOutcome />
                </div>

            </div>

            <div className={classes.whitePageWrapper}>

                <div className={classes.section} style={{paddingTop: 50}}>
                    <CourseContent />
                </div>

                {/*Removed as per content*/}
                {/*<div className={classes.section} style={{marginTop: matchesMdUp ? 100 : 0}}>
                    <VideoMarketing />
                </div>*/}

                <div className={classes.section} style={{marginBottom: 60}}>
                    <ActionReminder positionedAtRoot={true}/>
                </div>
            </div>

            <div className={classes.greyPageWrapper}>

                <div className={classes.section} style={{marginBottom: 60}}>
                    <CourseFor />
                </div>

            </div>

            <div className={classes.darkPageWrapper} style={{paddingTop: 60}}>

                <div className={classes.section} style={{paddingBottom: 60, maxWidth: 940}}>
                    <CourseNotFor />
                </div>

            </div>

            <div className={classes.whitePageWrapper}>

                <div className={classes.section} style={{paddingTop: 60}}>
                    <CourseCurriculum />
                </div>

                <div className={classes.section}>
                    <TimeTable />
                </div>
            </div>

            <div className={classes.darkPageWrapper}
                 // style={{paddingTop: 60}}
                 style={{paddingBlock: 60}}
            >

                {/*Removed as per content*/}
                {/*<div className={classes.section}>
                    <ExtraContent />
                </div>*/}

                {/*Removed as per content*/}
                {/*<div className={classes.section} style={{marginTop: matchesMdUp ? 50 : 0}}>
                    <VideoMarketing />
                </div>*/}

                <div className={classes.section}>
                    <ActionReminder positionedAtRoot={true}/>
                </div>

            </div>

            <div className={classes.whitePageWrapper}>

                <div className={classes.section}>
                    <ExtraPerks />
                </div>

                <div className={classes.section}>
                    <ExclusiveBenefit />
                </div>
            </div>

            {/*Removed as per content*/}
            {/*<div className={classes.darkPageWrapper} style={{paddingBlock: '16px'}}>

                <div className={classes.section} style={{paddingBottom: 30}}>
                    <Testimonial3 />
                </div>

                <div className={classes.section}>
                    <VideoMarketing />
                </div>

            </div>*/}

            <div className={classes.primaryPageWrapper}>

                <div className={classes.section}>
                    <MoneyBackPromise />
                </div>

            </div>

            <div className={classes.whitePageWrapper}>

                <div className={classes.section} style={{paddingBlock: '80px 40px'}}>
                    <FAQ />
                </div>

                {/*Removed as per content*/}
                {/*<div className={classes.section}>
                    <VideoMarketing />
                </div>*/}
            </div>

            <div className={classes.whitePageWrapper} style={{paddingTop: '60px 60px'}}>

                {/*<div className={classes.section}>
                    <Scholarship />
                </div>*/}

                <div className={classes.section}>
                    <ActionReminder positionedAtRoot={true}/>
                </div>

            </div>

            <div className={classes.darkPageWrapper}>
                <div className={classes.section}  style={{padding: '60px 60px'}}>
                    <Contact />
                </div>
            </div>

            <div className={classes.whitePageWrapper} style={{padding: matchesMdUp ? '34px 48px' : 16}}/>

            <div className={classes.greyPageWrapper} style={{paddingBottom: matchesMdUp ? 48 : 0}} />

            <div className={classes.darkestPageWrapper}>
                <div>
                    <Footer />
                </div>
            </div>

        </div>


    );
};

export default LandingPage;
