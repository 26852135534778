//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        color: '#FFF',
        padding: theme.spacing(4, 3.75),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 3.75),
        },
        "& > div": {
            padding: theme.spacing(4, 3.75),
            [theme.breakpoints.up('md')]: {
                padding: 0,
            }
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        paddingBottom: theme.spacing(7.5),
        "& span": {
            "&::before": {
                content: '"\\274C"'
            }
        }
    },
    body: {
        color: theme.palette.landingPage.body2,
        '& li': {
            ...theme.typography.landingPage.body,
            lineHeight: '35px',
        },
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            // paddingLeft: theme.spacing(10),
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
    divider: {
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(10),
        "& hr": {
            border: `solid ${theme.palette.landingPage.font2} 0.5px`
        }
    }
}));

const CourseNotFor = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span/></strong> Who&apos;s This Academy NOT For
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>

                <Typography>
                    This academy isn&apos;t for you if...
                </Typography>

                <Typography>
                    1. You&apos;re not willing to put in the time and effort to <b>make 1 video per week during the
                    program.</b>
                </Typography>

                <Typography>
                    2. You&apos;re looking for a magic bullet that&apos;ll get your channel/page to grow without any effort.
                    There&apos;s no secret here. It&apos;s just about putting in the work, doing it consistently, and
                    stacking the deck in your favour in whatever way you can.
                </Typography>

                <Typography>
                    3. You&apos;re hoping that starting a YouTube/Instagram/Facebook channel will be a <b>get-rich-quick
                    scheme. Again, that&apos;s not how it works. There are no shortcuts.</b>
                </Typography>

                <Typography>
                    4. You&apos;re looking for a guaranteed path to 10k, or 100k, or 1M subscribers/followers. There are
                    no guarantees in this game. It&apos;s like signing up for a writing course and expecting to have 1M
                    readers per month for your blog, or 100,000 sales of your book. That&apos;s not how it works.
                    We&apos;ll do our best to teach you the systems and processes that have worked for us and our
                    students, but we can&apos;t &apos;guarantee&apos; any specific result.
                </Typography>

                <div className={classes.divider}>
                    <hr/>
                </div>

                <Typography>
                    Having said all that, one thing we can guarantee is that if you create useful videos, publish 1-2
                    times per week, and do this for 2 years, your life will change. We can&apos;t put a number on it (no
                    one can), but if you can stick with this for 2+ years, you&apos;ll learn useful skills, you&apos;ll
                    generate &apos;passive&apos; income, and you&apos;ll make friends with amazing people from all over
                    the world. You might even get messages from fans about how your videos have changed their lives.
                </Typography>

                <Typography>
                    Our only objective on this program is to make it easier for you to maintain that level of
                    consistency. If that sounds like something you&apos;d like, we&apos;d love to have you. If it&apos;s
                    not what you&apos;re looking for, then sorry for wasting your time in reading this far, and we wish
                    you the very best of luck. Regardless, feel free to email us if you need a hand with anything!
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CourseNotFor;
