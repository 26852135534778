//React, React Router, Formik
import React from 'react';
import CustomImage from "@util/CustomImage/CustomImage";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper1: {
        padding: theme.spacing(0, 3.75),
    },
    wrapper2: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before": {
                content: '"\\1f680"'
            }
        }
    },
    body: {
        ...theme.typography.landingPage.body3,
        color: theme.palette.landingPage.font2,
        "&:not(last-child)": {
            marginBottom: theme.spacing(4)
        }
    },
    imageWrapper: {
        width: '100%',
        "& > span": {
            position: 'unset !important'
        },
        "& .custom-image": {
            objectFit: 'contain',
            width: '100% !important',
            height: '100% !important',
            position: 'relative !important',
        },
    }
}));

const MoneyMaking = () => {

    const classes = useStyles();

    const {assetPath} = useSelector(state => state.siteDetail);

    return (
        <div className={classes.wrapper1}>
            <Grid container className={classes.wrapper2}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        <strong><span></span></strong>Making Money from YouTube
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.body}>
                        7 years ago I started making YouTube videos while in <b>my final year of an
                        Engineering</b> college at
                        Rizvi college. I started off with <b>0 views, 0 subscribers, and ₹0 in revenue.</b> I knew
                        absolutely
                        nothing about animations, editing, or publishing videos, but after reading many books studying
                        other creators in detail understanding their secrets.
                    </Typography>

                    <Typography className={classes.body}>
                        Just 13 months later, my YouTube channel reached more than <b>225,000 subscribers</b> and was
                        making as
                        much money as my full-time creator then my seniors who started jobs 2 years before me...
                    </Typography>

                    <Typography className={classes.body}>
                        Now in August 2022, my channel has just passed <b>3.7 Million subscribers</b> and makes <b>7 figures each
                        month</b> that too with just 2 hours of effort on my part each WEEK, not even a day. And that&apos;s just
                        what the channel itself makes from Adsense and sponsorships - our overall business revenue if
                        you include courses, affiliates and products is much more — I have made 8 figures with my online
                        course too. I know this is amazing.
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.imageWrapper}>
                    <CustomImage
                        loaded={true}
                        src={`https://static.dbc.academy/abed1e5658b3ad23c38c22646968e4f2/files/media/images/2022/10/447aefcb-26fd-44cc-9d51-a11e7944c495/conversions/de31dba0fb4426e142198bf74d78d56d-1280x720.webp`}
                        alt="Image"
                        layout="fill"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default MoneyMaking;
