//React, React Router, Formik
import React, {useState} from 'react';
import Link from "next/link";
import {CHECKOUT, PRODUCT} from "@helper/constants/routes";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AuthModal from "@ui/AuthModal/AuthModal";

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        textAlign: 'center',
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    actionButtonWrapper: {
        paddingBottom: theme.spacing(3.75),
        "& .MuiButton-root": {
            ...theme.typography.landingPage.actionButton,
            paddingBlock: theme.spacing(6.25)
        }
    },
}));

const ActionReminder = ({positionedAtRoot}) => {

    const classes = useStyles();

    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('signIn');

    const {isAuthenticated, auth_user} = useSelector(state => state.auth);
    const {defaultProduct} = useSelector(state => state.siteDetail);

    const changeModalMode = (mode) => {
        setModalMode(mode);
    }

    const handleAuthModalOpen = (mode) => {
        setAuthModalOpen(true);
        changeModalMode(mode);
    };

    const handleAuthModalClose = () => {
        setAuthModalOpen(false);
    };

    return (
        <>
            {/*Auth Modal*/}
            {
                !isAuthenticated && authModalOpen &&
                <AuthModal
                    open={authModalOpen}
                    closeHandler={handleAuthModalClose}
                    modalMode={modalMode}
                    modalModeHandler={changeModalMode}
                />
            }

            <Grid container className={positionedAtRoot ? classes.wrapper : ''}>
                <Grid item xs={12} className={classes.actionButtonWrapper}>
                    {
                        !isAuthenticated && !authModalOpen ?
                            <Button onClick={() => handleAuthModalOpen('signUp')}>
                                Enroll Now
                            </Button> :
                            (
                                !auth_user?.has_membership ?
                                    <Link href={`${CHECKOUT.show}`}>
                                        <Button component="a">
                                            Enroll Now
                                        </Button>
                                    </Link> :
                                    <Link href={{
                                        pathname: PRODUCT.view,
                                        query: {slug: defaultProduct?.slug}
                                    }}>
                                        <Button component="a">
                                            Open
                                        </Button>
                                    </Link>
                            )

                    }
                </Grid>
            </Grid>
        </>
    );
};

export default ActionReminder;
