//React, React Router, Formik
import React, {useRef} from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import CustomImage from "@util/CustomImage/CustomImage";
import ImageCarousel from "@ui/LandingPage/Testimonial2/ImageCarousel/ImageCarousel";
import TextCarousel from "@ui/LandingPage/Testimonial2/TextCarousel/TextCarousel";
import Grid from "@material-ui/core/Grid";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(0, 3.75),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
        },
        "& > div": {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(5, 10),
            },
        }
    }
}));

const Testimonial2 = () => {

    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <ImageCarousel />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextCarousel />
                </Grid>
            </Grid>
        </div>
    );
};

export default Testimonial2;
