//React, React Router, Formik
import React, {useRef} from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import CustomImage from "@util/CustomImage/CustomImage";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    carousel: {
        "& .react-multi-carousel-track": {
            height: 280,
            [theme.breakpoints.up('476')]: {
                height: 360,
            },
            alignItems: 'center',
            "& .react-multi-carousel-item": {
                "&:not(.react-multi-carousel-item--active)": {
                    "& .custom-image": {
                        transform: 'scale(0.6)',
                        filter: 'blur(5.3px)'
                    }
                },
                "&.react-multi-carousel-item--active": {
                    zIndex: 1,
                    transform: 'scale(1.2)',
                    maxWidth: 272,
                    [theme.breakpoints.up('md')]: {
                        transform: 'scale(1.1)',
                    },
                    "& $imageWrapper": {
                        boxShadow: '0 0 0 20px rgb(222 207 196 / 30%)',
                        borderRadius: '50%'
                    }
                }
            }
        }
    },
    imageWrapper: {
        "& .custom-image": {
            borderRadius: '50%'
        },
    },
}));

const responsive = {
    mobile: {
        breakpoint: {max: 4000, min: 0},
        items: 1,
        slidesToSlide: 1,
    }
};

const ImageCarousel = () => {

    const classes = useStyles();

    let items = [
        {
            id: 1,
            src: 'https://samcart-foundation-prod.s3.amazonaws.com/marketplace-84349/assets/7ae2367f-030e-4e97-937e-fbae32cc94d7'
        },
        {
            id: 2,
            src: 'https://samcart-foundation-prod.s3.amazonaws.com/marketplace-84349/assets/8552c92b-6503-4a66-8020-91399ab05244'
        },
        {
            id: 3,
            src: 'https://samcart-foundation-prod.s3.amazonaws.com/marketplace-84349/assets/2f4ee981-fca9-4e75-b97d-91d7ce7bd374'
        },
    ];

    return (
        <div>
            <Carousel
                className={classes.carousel}
                responsive={responsive}
                arrows={false}
                autoPlay={true}
                infinite={true}
                centerMode={true}
            >
                {
                    items.map((item, index) => (
                        <div key={item.id} className={classes.imageWrapper}>
                            <CustomImage
                                loaded={true}
                                src={item.src}
                                alt="Profile Pic"
                                width={270}
                                height={270}
                            />
                        </div>
                    ))
                }

            </Carousel>
        </div>
    );
};

export default ImageCarousel;
