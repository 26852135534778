//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(8.25),
        "& .MuiTypography-root": {
            color: '#FFF',
            ...theme.typography.landingPage.subtitle2
        }
    }
}));

const Footer = () => {

    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Typography align="center">
                © 2022 Digital Business Creators Academy. All Rights Reserved.
            </Typography>
        </div>
    );
};

export default Footer;
