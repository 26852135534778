//React, React Router, Formik
import React from 'react';
import CustomImage from "@util/CustomImage/CustomImage";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        color: '#FFF',
        padding: theme.spacing(15, 0, 0),
        "& > div": {
            padding: theme.spacing(0, 3.75),
            [theme.breakpoints.down('sm')]: {
                padding: 0
            }
        }
    },
    title: {
        ...theme.typography.landingPage.heading2,
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before": {
                content: '"\\1F973"'
            }
        }
    },
    body: {
        "& > div": {
            margin: theme.spacing(0, 3.75),
            padding: theme.spacing(3.75),
            [theme.breakpoints.up('md')]: {
                maxWidth: 'calc(33.3333333% - 30px)',
                padding: 0,
            },

            "&:nth-child(3n + 1)": {
                [theme.breakpoints.up('md')]: {
                    paddingLeft: theme.spacing(3.75),
                    paddingRight: theme.spacing(1.85),
                },
            },
            "&:nth-child(3n + 2)": {
                [theme.breakpoints.up('md')]: {
                    paddingLeft: theme.spacing(1.85),
                    paddingRight: theme.spacing(1.85),
                },
            },
            "&:nth-child(3n + 3)": {
                [theme.breakpoints.up('md')]: {
                    paddingLeft: theme.spacing(1.85),
                    paddingRight: theme.spacing(3.75),
                },
            },
            "& .MuiTypography-root": {
                ...theme.typography.landingPage.body3,
                "&:not(last-child)": {
                    marginBottom: theme.spacing(4)
                },
                "& h2": {
                    //margin: theme.spacing(7.5)
                }
            }
        }
    },
    rocketEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\1f680"'
        }
    },
    airplaneEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\2708"'
        }
    },
    camcorderEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\1F3A5"'
        }
    },
    graphEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\1F4C8"'
        }
    },
    questionEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\2049"'
        }
    },
    recycleEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\267B"'
        }
    },
    heartEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\1FAC0"'
        }
    },
    moneyBagEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\1F4B0"'
        }
    },
    familyBagEmoji: {
        fontSize: '2rem',
        "&::before": {
            content: '"\\1F46A"'
        }
    },
}));

const CourseOutcome = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span/></strong> By the end of the 365-day academy…
                </Typography>
            </Grid>

            <Grid item container className={classes.body}>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.rocketEmoji}/></h2>
                        You&apos;ll have written <b>50 scripts that can easily be converted into 50 videos</b> that are
                        not boring and will even have the potential to get lakhs of views.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.airplaneEmoji}/></h2>
                        You&apos;ll have the next <b>3 months of content planned out,</b> and a simple system to achieve
                        it.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.camcorderEmoji}/></h2>
                        You&apos;ll be <b>comfortable in front of a camera,</b> with techniques to boost your ability to
                        persuade and influence with your words.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.graphEmoji}/></h2>
                        You&apos;ll get my recommendations for gear and <b>studio setups for all budget levels,</b> so
                        you can level up your production value while being focused on your content
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.questionEmoji}/></h2>
                        You&apos;ll know how to refine your niche, and you&apos;ll have a system that ensures
                        you&apos;ll <b>never run out of content ideas</b> - The Idea Generation Machine.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.recycleEmoji}/></h2>
                        You&apos;ll have various <b>methods and frameworks for structuring your videos</b> in a way that
                        appeals to your audience and to the YouTube algorithm.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.heartEmoji}/></h2>
                        You&apos;ll understand how to accelerate your growth by efficiently <b>repurposing your
                        content</b> to multiple platforms with one to many strategies.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.moneyBagEmoji}/></h2>
                        You&apos;ll understand the various <b>monetization options</b> available to creators, and
                        you&apos;ll know how to use them to <b>generate passive income.</b>
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography>
                        <h2><span className={classes.familyBagEmoji}/></h2>
                        You&apos;ll know the value of <b>building a team</b> as soon as you can, and you&apos;ll have a
                        roadmap
                        for <b>outsourcing your editing</b> and hiring extra team members.
                    </Typography>
                </Grid>

            </Grid>
        </Grid>
    );
};

export default CourseOutcome;
