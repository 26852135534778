//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Hidden, Typography} from "@material-ui/core";
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4, 1.5, 4.75),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5, 10)
        }
        /*paddingBlock: theme.spacing(4, 4.75),
        paddingLeft: theme.spacing(3.75),
        paddingRight: theme.spacing(3.75),*/
        /*[theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
        }*/
    },
    blockquote: {
        margin: 0,
        position: 'relative',
        marginBottom: 0,
        padding: '5.9782608696% 8.152173913%',
        color: props => props.background === 'dark' ? theme.palette.landingPage.body2 : theme.palette.landingPage.font4,
        border: `1px solid ${theme.palette.landingPage.secondary}`,
        boxShadow: '0 8px 19px rgb(62 189 255 / 13%), 0 0 0 6px rgb(0 168 255 / 14%)',
        borderRadius: 10,
        /*[theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginRight: 0
        }*/
    },
    quoteIcon: {
        position: 'absolute',
        top: -17,
        left:'50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        background: theme.palette.landingPage.secondary,
        borderRadius: '50%',
        "& .MuiSvgIcon-root": {
            width: 32,
            height: 32,
            fill: '#FFF',
            transform: 'rotate(180deg)',
        }
    },
    content: {
        ...theme.typography.landingPage.body3,
    },
    footer: {
        marginTop: theme.spacing(6.25),
        "& .MuiTypography-root": {
            marginBottom: 0,
        }
    },
    name: {
        ...theme.typography.landingPage.title6,
        color: props => props.background === 'dark' ? theme.palette.landingPage.body2: theme.palette.landingPage.font4,
    },
    profession: {
        color: props => props.background === 'dark' ? theme.palette.landingPage.font : theme.palette.landingPage.font4,
    }
}));

const TestimonialItem = ({testimonial, ...props}) => {

    const classes = useStyles(props);

    return (
        <div className={classes.root}>

            <blockquote className={classes.blockquote}>
                <div className={classes.quoteIcon}><FormatQuoteIcon /></div>
                <Typography className={classes.content}>
                    {testimonial.body}
                </Typography>

                <Hidden smUp>
                    <div className={classes.footer}>
                        <Typography align="center" className={classes.name}>
                            {testimonial.name}
                        </Typography>

                        <Typography align="center" className={classes.profession}>
                            {testimonial.profession}
                        </Typography>
                    </div>
                </Hidden>
            </blockquote>

        </div>
    );
};

export default TestimonialItem;
