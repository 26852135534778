//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import CustomImage from "@util/CustomImage/CustomImage";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    imageWrapper: {
        paddingBlock: theme.spacing(10.5, 4),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '86%',
            margin: '0 auto'
        },
        "& > span": {
            position: 'unset !important'
        },
        "& .custom-image": {
            //objectFit: 'contain',
            width: props => [props.width, '!important'],
            height: props => [props.height, '!important'],
            position: 'relative !important',
            borderRadius: 8
        },
        /*"& > span": {
            position: ['relative', '!important'],
            width: props => [props.width, '!important'],
            height: props => [props.height, '!important'],
        },
        "& .custom-image": {
            borderRadius: 8
        }*/
    },
    titleWrapper: {
        paddingBlock: theme.spacing(3.75, 4),
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.heading1,
            [theme.breakpoints.up('md')]: {
                ...theme.typography.landingPage.title5,
            }
        }
    },
    bodyWrapper: {
        padding: theme.spacing(4, 10.5),
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            fontSize: 18,
            color: theme.palette.landingPage.font2
        }
    },
}));

const CourseContentCard = ({imageSrc, title, body, ...props}) => {

    const classes = useStyles(props);

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item xs={12} className={classes.imageWrapper}>
                <CustomImage
                    loaded={true}
                    src={imageSrc}
                    alt="Image"
                    layout="fill"
                />
            </Grid>

            <Grid item className={classes.titleWrapper}>
                <Typography align="center">
                    {title}
                </Typography>
            </Grid>

            <Grid item className={classes.bodyWrapper}>
                <Typography>
                    {body}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CourseContentCard;
