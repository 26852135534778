//React, React Router, Formik
import React from 'react';
import CustomImage from "@util/CustomImage/CustomImage";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import CourseContentCard from "@ui/LandingPage/CourseContentCard/CourseContentCard";
import Testimonial2 from "@ui/LandingPage/Testimonial2/Testimonial2";
import Testimonial1 from "@ui/LandingPage/Testimonial1/Testimonial1";
import testimonialContent from "@modules/landing-page/CourseContent/TestimonialContent";
import {useSelector} from "react-redux";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 3.75),
        },
        "& > div": {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(4, 3.75),
            }
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    cardContainer: {
        paddingBlock: theme.spacing(4)
    },
    cardWrapper: {
        "&:first-child": {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 3, 0, 6)
            }
        },
        "&:last-child": {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 6, 0, 3)
            }
        },
    },
    subtitleWrapper: {
        paddingBlock: theme.spacing(4),
        "& .MuiTypography-root": {
            color: theme.palette.landingPage.font,
            ...theme.typography.landingPage.heading1,
            [theme.breakpoints.up('md')]: {
                ...theme.typography.landingPage.title5,
            }
        }
    },
    subtitleBodyWrapper: {
        padding: [theme.spacing(4, 19), '!important'],
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            color: theme.palette.landingPage.font
        }
    }
}));

const CourseContent = () => {

    const classes = useStyles();

    const {assetPath} = useSelector(state => state.siteDetail);

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    What You Can Expect
                </Typography>
            </Grid>


            <Grid item container xs={12} className={classes.cardContainer}>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        //imageSrc="https://samcart-foundation-prod.s3.amazonaws.com/marketplace-84349/assets/1f37ef23-69ee-4993-812c-595c008a0a5c"
                        imageSrc={`https://static.dbc.academy/abed1e5658b3ad23c38c22646968e4f2/files/media/images/2022/10/b2aa3590-8a64-4610-94da-135a8feb26a7/conversions/cc1daf82c8b04aef2e941a944fdde7ed-1280x720.webp`}
                        title="Core Live Sessions with SeeKen (Zeeshan)"
                        body="These will be held weekly once, timing will be shared on your exclusive WhatsApp group. Live will be the place I'll deliver the core program content and systems. Each live session will be 30 - 60 minutes of teaching, followed by an open Q&A where you'll be able to directly ask me any questions you want."
                        width="auto"
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://static.dbc.academy/abed1e5658b3ad23c38c22646968e4f2/files/media/images/2022/10/0063e7b1-3d97-4da6-baf6-6f70615335f8/conversions/9e0e5a390038a36f5b5893f035e22e1a-1280x720.webp`}
                        title="Core Live Sessions with SeeKen Jigyaas (Shubham)"
                        body="These sessions will be held once a month, again timing will be shared on your exclusive WhatsApp group. Live will be the place Shubham will deliver the core program content and systems. Each live session will be 30 - 60 minutes of teaching, followed by an open Q&A where you'll be able to directly ask him any questions you want."
                        width="auto"
                        height="auto"
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.subtitleWrapper}>
                <Typography align="center">
                    Guest Sessions
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.subtitleBodyWrapper}>
                <Typography>
                    We are going to bring some great creators and mentors of this industry to talk about their secrets and systems and how you could follow the same to grow.
                </Typography>
            </Grid>

            {/*Removed as per content*/}
            {/*<Grid item xs={12}>
                <Testimonial2/>
            </Grid>*/}

            <Grid item container xs={12}>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://static.dbc.academy/abed1e5658b3ad23c38c22646968e4f2/files/media/images/2022/10/01e4da80-1834-467b-9bf9-146a34c17c19/conversions/c4dab46b80eae20006bce20bd953bd02-1280x720.webp`}
                        title="Friday Q&A Panels"
                        body="Every Friday I'll be hosting a Q&A session where you can ask me any and all questions related to your YouTube/Instagram/Facebook channel, content, strategy, or anything else. We'll also bring DBC alumnus on live and discuss things that will give you multiple perspectives and answers to your questions."
                        width="auto"
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://static.dbc.academy/abed1e5658b3ad23c38c22646968e4f2/files/media/images/2022/10/c0386fd7-24ee-4c6f-afa7-c230d9647658/conversions/d9b66584aeb450a35ccc83b18a0e7b90-1280x720.webp`}
                        title="Video Feedback"
                        body="Each week I will ask you to send your videos to me so that I can check and give feedback on some of them. This'll look at everything from titles + thumbnails and video structure, to editing and camera confidence, and so much more. It's all aimed at helping you improve week-by-week."
                        width="auto"
                        height="auto"
                    />
                </Grid>
            </Grid>

            {/*Removed as per content*/}
            {/*<Grid item xs={12}>
                <Testimonial1 testimonialContent={testimonialContent}/>
            </Grid>*/}

        </Grid>
    );
};

export default CourseContent;
