const AccordContent = [
    {
        id: 1,
        title: '0 - 1,000 Subscribers',
        body: `
            This is the hardest phase of being a Creator. You’ve probably put off starting the channel for a while and you’re brand new to the game. It’s the toughest part of the learning curve, and you’re overcoming your procrastination to put yourself out there.
            <br>
            <br>
            Your next steps - You’ve passed Level 1 of our Framework to Get Started and now you’re working on Level 2 (Get Better). Your main gains will come from improving at the craft of making videos while
            <br>
            <br>
            How the program can help - The program will be most useful if (a) you’re committed to taking Content creation seriously, and (b) you’re joining the program for the accountability, community, and support, rather than just the content alone.
            <br>
            <br>
            If we’re being honest, most of the content in the program can be better applied when you’re a Creator with more experience. 50% of the material is around what it takes to make amazing videos, and the other 50% is around strategy, systems, expansion, and monetization. When you’re just starting out, this stuff could feel very overwhelming.
            <br>
            <br>
            If you’re in this camp however, a huge beginner benefit is the accountability and the community around the course. It’ll make it endlessly easier to get you over that hurdle of getting started and to encourage you to take content creation seriously. The video feedbacks will massively accelerate your learning curve, and the support from me and my team will get all the questions that start arising answered. The other good news is you’ll have the recordings of all the lessons and be in contact with us forever in the community, so you will always have answers and support when needed in the future too from the community.
            <br>
            <br>
            To get you ready to start filming from day one, you’ll get free access to all my pre-recorded Getting Started Videos, which you should watch and take action before the live course starts. You’ll also get free access to my Content Writing Course, Passion Course, Video Editing with Premier Pro Course, and Video Editing with Mobile Course. Do shoots with mobile if you are a beginner and don't want to invest on a huge camera.
        `
    },
    {
        id: 2,
        title: '1,000 - 10,000 Subscribers',
        body: `
            Congrats, you’re putting videos out but you’re still at the hardest stage of growth. The first 1,000 subscribers are the most difficult. You probably have some idea of what you’re doing right, but you might still not be entirely sure why you aren’t getting the uneven views you want from YouTube/Instagram/facebook. You might already be feeling the pain of balancing making videos consistently with your day-to-day life, and you might be struggling with the important question of “what’s my niche?”
            <br>
            <br>
            Your next steps - You’ve passed Level 1 of our YouTube Growth Framework (Get Going) and you’re now working on Level 2 (Get Good). Your main YouTube gains will come from improving at the craft of making videos, while spending a small amount of time thinking strategically about your niche, target audience and competitive advantages.
            <br>
            <br>
            spending a small amount of time thinking strategically about your niche, target audience, and competitive advantages.
            <br>
            <br>
            How the program can help - For the start live-session content will be focused on how you can Get Good at making videos - content writing, Research, titles, thumbnails, structures, branding, tools to use, etc. Lots of our live workshops are focused around this goal as well. These will definitely be very helpful for you.
            <br>
            <br>
            Later the program content will focus around expansion, distribution, monetization, etc, might not be fully relevant to your stage just now, but it’ll be useful to get an idea of what’s possible. If you end up falling behind, don’t worry - there’s a lot of content. You’ll have access to the recordings and us forever, and we can definitely help you out with questions as they become relevant to your channel with weekly live QnA.
            <br>
            <br>
            Another huge benefit to you will probably be the personalized video feedback. You’ll be getting mini-audits on randomly selected videos students publish during the course, with specific advice and suggestions on what you’re doing right and what you can improve on. This will hopefully massively accelerate your improvement process.
            <br>
            <br>
            You’ve probably also racked up a few questions about your channel, your content, your strategy, the YouTube algorithm, and viewer behavior already. Let’s get those all answered and clarified in multiple Q&A sessions with me and the team :)

        `
    },
    {
        id: 3,
        title: '10,000 - 100,000 Subscribers',
        body: `
            This is where it starts to really get fun. You’ve passed the 10k mark, you’ve clearly been making videos that work and you probably have an idea of what’s good for you on YouTube/Instagram/Facebook. But, you might not be entirely certain on how to get yourself to the next level. You’re not yet making the money you want on the platform, and you’re not sure you’re spending your limited time in ways that maximize your results. We’ve got you.
            <br>
            <br>
            Your next steps - You’re probably at Level 3. You know how to make ‘good’ videos that you don’t cringe at, and your videos are clearly hitting off with some sort of audience. Your biggest gains are now going to come from Getting Smart - from treating your YouTube/Instagra/Facebook more like a business.
            <br>
            <br>
            How this program can help - It’s time for you to take things to the next level. But again, the basics. We need to make sure your videos are actually the best they can be. The first of the program videos are focused on video quality: it’s time for you to make sure you’re fully up to speed with the best practices and strategies on YouTube for video hooks, titles, thumbnails, content, structure, and topics. It’s always valuable to re-approach your target audience and niche and make sure you’re actually making the right videos for the right people, even if you’re a ‘bigger’ YouTuber.
            <br>
            <br>
            You’ll also really benefit from our personalized video feedback. Getting some critical eyes on your videos will help point out what you’re currently doing that’s working well and can be improved. And then we’ll show you how to make those changes in a systematic way that won’t require hours and hours of time and effort.
            <br>
            <br>
            But it’s really the second half of the program that’s key for you. We’ll show you exactly how to put systems in place for doing what you’re currently doing faster, more efficiently, more effectively, and eventually better. We'll teach you the ins and outs of outsourcing so that you can get editing (and maybe other aspects of your process) off your hands so you can focus on the really fun stuff. And you’ll also get to have a transparent look into what monetization, numbers, and communication with brands look like at various stages of growth to help you take up financial opportunities you might already be overlooking or are building towards.
            <br>
            <br>
            Finally, our guest workshops and Q&As with me and other large creators will be a great chance for you to deep dive into what’s working for other successful YouTubers. And you already probably have a bunch of questions and concerns that you’ve racked up from all your time on the platform. There are endless opportunities during the program for me and the team to directly answer these for you. We’ll help take you to the next level.
            <br>
            <br>
            Bonus - You'll get access to our invite-only "10K+ Sub Club", a private Whatsapp group for YouTubers of your size. The group will also meet once a week during the course, for special sessions. It'll be a great way for you to meet other YouTubers of similar experience, and share struggles and tips with like-minded peers.
        `
    },
    {
        id: 4,
        title: '100,000 - 10,00,00 Subscribers',
        body: `
            You’re becoming a pro (and we hope it feels like it). You’ve been publishing videos for a while now and got videos that have worked very well with your audience. You’ve been making some money on the platform and have probably been doing this thing for a while, but you might be in a bit of a rut. You’re not seeing the results you really want: views, engagement, money, and growth. You’re not sure what the best next step is for you and your channel.
            <br>
            <br>
            Your next steps - You’re in the final stages of the growth. You know how to make decent videos, your videos are resonating with your audience, and you want to treat this YouTube/Instagram/Facebook thing as a business. At this point, you want to focus on optimizing your content-creation workflow (through strategy and systems), figure out good ways to monetize your existing audience in a non-scammy way, and work on outsourcing the aspects of your production process that don’t excite you anymore.
            <br>
            <br>
            How the program can help - Content-wise, the first half of the program is aimed at leveling up your videos, in terms of titles, thumbnails, hooks, structure, branding, storytelling, production value, and vibe. You’ve probably got most of these fundamentals down, but improving video quality is always a work-in-progress and you’ll hopefully pick up some interesting techniques that you can start to apply to your work immediately.
            <br>
            <br>
            The second half of the program should be particularly interesting and applicable for you - that’s where we focus on expansion, distribution, monetization, and systemization. We’ll teach you how to syndicate your content to multiple platforms, how to build a team around your business so that you’re having a lot of free time for yourself, and how to think about monetization beyond Adsense and brand deals.
            <br>
            <br>
            The personalized video feedback you’ll get on your videos should be pretty helpful too - especially as experienced YouTubers, it’s easy for us to forget that there are always things we can do to level-up our video quality. The feedback from our team should give you a somewhat objective extra-pair-of-eyes on your videos to tweak their quality.
            <br>
            <br>
            Our guest workshops with large creators will be a great chance for you to deep dive into what’s working for other successful YouTubers. And you already probably have a bunch of questions and concerns that you’ve racked up from all your time on the platform. There are endless opportunities during the program for me and the team to directly answer these for you. We’ll help take you to the next level.
            <br>
            <br>
            Bonus - You'll get access to our invite-only "100K+ Sub Club", a private Whatsapp group for YouTubers of your size. The group will also meet once a week during the course, for special sessions. It'll be a great way for you to meet other YouTubers of similar experience, and share struggles and tips with like-minded peers.
        `
    },
    {
        id: 5,
        title: '10,00,000+ Subscribers',
        body: `
            You’re in a good category- you’re one of a handful of pro creators (YouTube/Instagram/Facebook). Your content is clearly resonating with your audience, you’ve probably been doing it for ages, and you’re looking for ways to level-up and take your entire business to the next level in a sustainable fashion.
            <br>
            <br>
            Your next steps - Most of your gains at this point are going to come from actively treating your creative hustle as more of a business. By building systems to optimize your workflow, and eventually by building a team to leverage your own time and attention, you’ll be able to take things to the next level.
            <br>
            <br>
            The video feedback will also really help with this. You’ll get trained eyes on your channel (potentially for the first time) giving you mini-audits on 5 videos you’ll publish during the program, with some ideas on what you’re doing right on YouTube and what you can potentially improve on.
            <br>
            <br>
            How the program can help - The first half of the program content around making better videos (titles, thumbnails, hooks, structure, branding, personality, vibe, production value) should be a useful reminder of the foundations of decent videos. Most of it won’t be mind-blowing stuff, but you’ll probably come across some interesting ways of looking at content that you hadn’t before. This is a good space to also make sure you’re sticking to best practices for videos and it’s always valuable to re-think and strategise your niche and target audience to make sure you’re making the right content for the right people.
            <br>
            <br>
            The second half of the program, around building systems, distribution, expansion, and monetization, should be very relevant to you. At your stage of experience, you could definitely be making a large amount of money on YouTube, if you approach monetization in an entrepreneurial fashion, which you will learn from me.
            <br>
            <br>
            By the end of DBC, you’ll hopefully be re-inspired, out of any potential content creation slump you’re in, buzzing with new ideas, have a bunch of improvements for your channel lined up, have a monetization roadmap that should have you super-excited and most importantly, have a system in place that will allow you to do all this part-time if you want to.
            <br>
            <br>
            Bonus - You'll get access to our invite-only "1M+ Sub Club", a private Whatsapp group for YouTubers of your size. The group will also meet once a week during the course, for special sessions. It'll be a great way for you to meet other YouTubers of similar experience, and share struggles and tips with like-minded peers.
        `
    },
];

export default AccordContent;
