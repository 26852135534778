//React, React Router, Formik
import React from 'react';
import Testimonial1 from "@ui/LandingPage/Testimonial1/Testimonial1";
import testimonialContent from "@modules/landing-page/ExtraContent/TestimonialContent";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
}));

const ExtraContent = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>

            <Grid item xs={12}>
                <Testimonial1 testimonialContent={testimonialContent} background="dark"/>
            </Grid>
        </Grid>
    );
};

export default ExtraContent;
