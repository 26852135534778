//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import AccordContent from "@modules/landing-page/FAQ/AccordContent";
import AccordPanel from "@ui/LandingPage/AccordPanel/AccordPanel";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before":  {
                content: '"\\1F914"'
            }
        }
    },
    body: {
        /*color: theme.palette.landingPage.font2,
        '& li': {
            ...theme.typography.landingPage.body,
            lineHeight: '35px',
        },
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            paddingLeft: theme.spacing(10),
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }*/
    },
}));

const FAQ = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span /></strong> Frequently Asked Questions
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body} style={{maxWidth: 940, margin: 'auto'}}>
                <div>
                    {
                        AccordContent.map(row => (
                            <AccordPanel
                                key={row.id}
                                row={row}
                                /*expandedAccord={expandedAccord}
                                expandedAccordionHandler={handleChangeAccordion}*/
                            />
                        ))
                    }
                </div>
            </Grid>
        </Grid>
    );
};

export default FAQ;
