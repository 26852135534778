//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before": {
                content: '"\\1F393"'
            }
        }
    },
    body: {
        color: theme.palette.landingPage.font2,
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body2,
            paddingLeft: theme.spacing(10),
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
    emojiSmile: {
        "&::before": {
            content: '"\\1F642"'
        }
    }
}));

const Scholarship = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span/></strong> Scholarships
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>

                <Typography>
                    We know that some people might not be able to afford PTYA. We don’t want the price to exclude
                    individuals who are passionate about successfully growing their YouTube channel.
                </Typography>

                <Typography>
                    That’s why we’re offering some full scholarships to individuals who can show an enthusiasm and
                    passion for growing their channel and who&apos;d benefit from the content of the course. To apply, you
                    must be over 18 and have an existing YouTube channel with at least 3 published, full length videos
                    (not just Shorts). You should also be available for the 5 weeks of the course which starts on July
                    11th 2022.
                </Typography>

                <Typography>
                    If you meet these requirements, please write answers to ALL the questions in this form and attach a
                    link to an Unlisted YouTube video - NO LONGER than 2 minutes in length - introducing yourself,
                    explaining why you’d benefit from the course, why you’re applying for a scholarship, and finally
                    what your goals and aspirations are for your channel. We’ll be capping applications at 200
                    applicants so be sure to get yours in as soon as you can.
                </Typography>

                <Typography>
                    Applications which don’t answer all the questions, or which provide a video longer than 2 minutes
                    won&apos;t be considered.
                </Typography>

                <Typography>
                    Applications are open from 1600 BST on Monday June 27th and will close at 2359 BST on Thursday 30th
                    June or when we’ve received 200 applications (whichever comes first). Just as a heads up, we’ll get
                    back to you with our decision by Tuesday 5th July which will be after the enrolment for the course
                    has closed. Thanks and good luck! <span className={classes.emojiSmile}/>
                </Typography>

                <Typography>
                    (Please make sure your application video is ‘Unlisted’ rather than ‘Private’ otherwise we won’t be
                    able to see it!)
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Scholarship;
