//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        textAlign: 'center',
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
            "& .MuiTypography-root": {
                ...theme.typography.landingPage.title4
            }
        }
    }
}));

const EnrolmentStatus = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography>
                    ⏰ ENROLMENT OPEN FOR NOW
                </Typography>
            </Grid>
        </Grid>
    );
};

export default EnrolmentStatus;
