//React, React Router, Formik
import React, {useState} from 'react';
import AccordContent from "@modules/landing-page/CourseFor/AccordContent";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import AccordPanel from "@ui/LandingPage/AccordPanel/AccordPanel";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before": {
                content: '"\\1F468\\200D\\1F4BB"'
            }
        }
    },
    body: {
        color: theme.palette.landingPage.font2,
        '& li': {
            ...theme.typography.landingPage.body,
            lineHeight: '35px',
        },
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            paddingLeft: theme.spacing(10),
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
    wrapper1: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6)
        }
    },
    wrapper2: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(10)
        }
    }
}));

const CourseFor = () => {

    const classes = useStyles();

    const [expandedAccord, setExpandedAccord] = useState(false);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        // console.log('isExpanded: ', isExpanded);
        setExpandedAccord(isExpanded ? panel : false);
    };

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span/></strong> Who&apos;s the Program For?
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>

                <Typography align="center">
                    Basically, the program is for <b>anyone who wants to take YouTube/Instagram/Facebook
                    seriously.</b> If you&apos;re committed to growing your online brand and putting yourself in the
                    best position to build, sustain and succeed at this creator economy thing, we&apos;re here for you,
                    and we promise you&apos;ll get large amounts of value from the DBC.
                </Typography>

                <Typography align="center">
                    The specifics of what this looks like can get a bit complicated, because (a) the program is pretty
                    diverse, and (b) the specific value you&apos;ll get from it does depend on your Social media experience
                    level. We&apos;ve tried to break things down (very roughly) by subscriber/follower count. Naturally,
                    this is an imperfect metric, but it&apos;s the best we&apos;ve got to approach this, so please take
                    the specific breakdown with a pinch of salt.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <div className={classes.wrapper1}>
                    <div className={classes.wrapper2}>
                        {
                            AccordContent.map(row => (
                                <AccordPanel
                                    key={row.id}
                                    row={row}
                                    expandedAccord={expandedAccord}
                                    expandedAccordionHandler={handleChangeAccordion}/>
                            ))
                        }
                    </div>
                </div>
            </Grid>

        </Grid>
    );
};

export default CourseFor;
