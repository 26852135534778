//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before": {
                content: '"\\1F3AF"'
            }
        }
    },
    body: {
        color: theme.palette.landingPage.font2,
        '& li': {
            ...theme.typography.landingPage.body,
            lineHeight: '35px',
        },
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            paddingLeft: theme.spacing(10),
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
}));

const ChallengesOfYoutuber = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span/></strong> The Challenges of Being a Creator
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>
                <ul>
                    <li>It feels frustrating to create videos and not see results as expected.</li>
                    <li>You&apos;ll have to get over the fear of judgment from friends, family, and strangers on the
                        internet.
                    </li>
                    <li>You&apos;ll have to work out what you&apos;re going to talk about + why anyone would care what you&apos;ve got
                        to say.
                    </li>
                    <li>You&apos;ll need to learn about content writing, animations, voice-over, storytelling, camera
                        setting, microphones, lights, video editing, and post-production.
                    </li>
                    <li>You&apos;ll worry that you&apos;re going to run out of ideas.</li>
                    <li>You&apos;ll always wonder and try to crack the algorithm of youtube, Instagram, and Facebook.</li>
                    <li>Wondering how much money you should charge at every level for promotions and trying to negotiate
                        the best deal you can.
                    </li>
                </ul>

                <Typography>
                    And you&apos;ll have to do all this and a lot more while juggling college, job, relationships,
                    family, and health, and also doing your best to enjoy the journey and avoid burnout.
                </Typography>

                <Typography>
                    <b>This is hard.</b> It takes a lot of work.
                </Typography>

                <Typography>
                    <b>But it&apos;s also really fun</b>, and if you can make it work, it&apos;s
                    genuinely <b>life-changing.</b>
                </Typography>

                <Typography>
                    The real &quot;secret&quot;, if there&apos;s one at all, is that you need to build a <b>SYSTEM &
                    PROCESSES</b> around
                    your content production, so that you can efficiently pump out useful content that grows your
                    audience and revenue, without it taking up large amounts of your own time.
                </Typography>

                <Typography>
                    Instead of thinking of your YouTube/Instagram/Facebook page as a personal project, you want to think
                    about it like a machine. A machine that takes inputs in the form of ideas, refines them into
                    valuable content that your audience loves, and efficiently monetizes itself across multiple income
                    sources.
                </Typography>

                <Typography>
                    I&apos;ve built such a <b>SYSTEM & PROCESSES</b> for my channels/pages and business, and I can help you build
                    yours too.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ChallengesOfYoutuber;
