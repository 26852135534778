//React, React Router, Formik
import React from 'react';
import CustomImage from "@util/CustomImage/CustomImage";
import testimonialContent from "@modules/landing-page/PartTimeYoutuber/TestimonialContent";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import Testimonial1 from "@ui/LandingPage/Testimonial1/Testimonial1";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before": {
                content: '"\\1F9E0"'
            }
        }
    },
    body: {
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            color: theme.palette.landingPage.font2,
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
}));

const PartTimeYoutuber = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span/></strong> The Digital Business Creators Academy (DBC)
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>

                <Typography>
                    The Digital Business Creators Academy is a <b>365-DAY online program</b> where <b>l will teach and help you implement </b>
                    everything I know about growing your YouTube/Instagram/Facebook into a sustainable machine that
                    provides valuable content and generates massive passive income. Without you having to quit your
                    studies or work.
                </Typography>

                <Typography>
                    During the 365 days, we&apos;ll have a combination of live zoom lessons, open live Q&As, feedback on selected channels and videos, exercises, and tasks to help boost your YouTube/Instagram/Facebook journey while connecting and having fun with hundreds of other creators/influencers on the same path.
                </Typography>

                <Typography>
                    The systems we&apos;ve developed and teach on the program grew my own channels from zero to 3.7 million subscribers (with crore+ in annual revenue) while working on other startups like <a href="onlinevideobooks.com">OnlineVideoBooks.com</a>.
                </Typography>

            </Grid>

            {/*Removed as per Content*/}
            {/*<Grid item xs={12}>
                <Testimonial1 testimonialContent={testimonialContent}/>
            </Grid>*/}
        </Grid>
    );
};

export default PartTimeYoutuber;
