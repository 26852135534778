const AccordContent = [
    {
        id: 1,
        title: 'What will be the language of the program?',
        body: `All live seasons and recorded will be in Hindi just like my YouTube/Instagram/Facebook videos.`
    },
    {
        id: 2,
        title: 'How long does the program last?',
        body: `The program lasts 365 days.`
    },
    {
        id: 3,
        title: 'Do I need to have a YouTube/Instagram/Facebook channel/page to join the program?',
        body: `No, it's not compulsory but I'd recommend that you set up a channel/page before the start of the program.`
    },
    {
        id: 4,
        title: 'Do I need to have a certain number of subscribers/followers to join the program?',
        body: `No. We do recommend that you have a channel set up. hope this program helps everyone move their channels/pages forward regardless of what stage of the journey they're at.`
    },
    {
        id: 5,
        title: 'What experience with YouTube do I need to have?',
        body: `No past experience of YouTube/Instagram/Facebook is required, you just need a desire to make YouTube/Instagram/Facebook part of your future`
    },
    {
        id: 6,
        title: 'I’ve made 100 videos already, is this program for me?',
        body: `Of course :) The program is aimed at everyone of all abilities. Having 100 videos provides you with a really solid foundation to grow, monetize and scale your channel/page and we hope we can help you do that through the lessons on the program.`
    },
    {
        id: 7,
        title: 'What sort of channel is the program suitable for?',
        body: `The program is not tailored to any specific genre/style of video. If your videos try to provide value to your audience, the exact type of your channel won't matter. In fact, I think the system and processes in the program can be applied to any genre of video and we'll be on hand to help make sure that you feel able to apply these methods if you encounter any issues.`
    },
    {
        id: 8,
        title: 'What is the minimum equipment required to take the program?',
        body: `There are no requirements for equipment and gear. All you need is something to film and write with so your phone is perfectly fine, that's how I started back in 2015.`
    },
    {
        id: 9,
        title: 'How involved will Zeeshan (SeeKen) be with the Live Sessions?',
        body: `I'll host one Keynote session per week. And on top of that, we also have additional workshops (both live and recorded) where some of my internet and YouTube/Instagram friends will provide extra teaching.`
    },
    {
        id: 10,
        title: 'Is it possible to pay in installments?',
        body: `Absolutely. If you're unable to pay the full amount up front. A slight head's up - you end up paying slightly more if you go for the installments option.`
    },
    {
        id: 11,
        title: 'Do I need to know how to edit videos?',
        body: `Yes and no ? You do need to edit your videos so prior experience will be beneficial but for everyone that doesn't have editing skills, we'll run two video editing crash course during the onboarding week. You don't need to be able to edit well, but you do need to be able to put a video together by the time the program begins so that you can make the most of everything.`
    },
    {
        id: 12,
        title: 'What time are the live sessions?',
        body: `Live sessions will be hosted on Tuesday, and Friday from 2 PM. On top of that, we'll host Guest Seminars on Wednesdays.`
    },
    {
        id: 13,
        title: 'Do I need to attend all the live sessions?',
        body: `Definitely not. This is a Part-Time Academy and therefore it's designed in a way that you can fit it in your already busy calendar and get the most out of the program without attending all of the live sessions. We'll encourage you to pick the ones that strike your interest and attend those. You can always watch the rest using your lifetime access to session recordings.`
    },
    {
        id: 14,
        title: 'Can I get an invoice?',
        body: `No problem, just let us know during the enrolment and we’ll send it over.`
    },
];

export default AccordContent;
