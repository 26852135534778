const AccordContent = [
    {
        id: 1,
        title: 'SECTION 1: Weekly Live by Zeeshan Shaikh',
        body: `
            Will be held on Every Tuesday (Time will be shared on community)Features of Live Sessions:
            <br>
            <ul>
                <br>
                <li>Questions and Answers</li>
                <li>Feedback related to Content of our Academy Members</li>
                <li>Will Address on One Topic which is a Pain point of our creator</li>
                <li>In depth Knowledge which is required to become a content creator</li>
            </ul>
        `
    },
    {
        id: 2,
        title: 'SECTION 2: Discovering Your Potential',
        body: `
            Your potential is ultimately what you work for. Thus, it’s best to work for the things that you desire, so that you enjoy the process. Think of the things that you enjoy or would want to be good at.
            <br>
            <br>
            Defining goals will help you stay on track to understand your potential.This Section will help you to find your potential and help you develop a skillset which is essential to become a content creator.
            <br>
            <ul>
                <br>
                <li>Introduction </li>
                <li>How to Find Your Niche</li>
                <li>Questions that will Help You to Find Your Niche</li>
                <li>How to Define Your Niche</li>
                <li>Approach to Find Your Niche</li>
                <li>Define Your Niche</li>
                <li>What If I am Shy</li>
                <li>Do Gears Matters</li>
                <li>Brain and Ideas</li>
                <li>Communication</li>
                <li>Initial Efforts Before Success</li>
                <li>3 Important Factors to Make a Viral Content</li>
                <li>Being Authentic</li>
                <li>Power of Emotions</li>
                <li>Use of Social Media Intentionally</li>
                <li>Power of One</li>
                <li>Quality vs Quantity</li>
                <li>Four Factor of Success</li>
                <li>Hobbies vs Business</li>
                <li>Power of Polarization</li>
                <li>Common Sense</li>
                <li>Taking Challenges</li>
                <li>Adaptive Mentality</li>

            </ul>
        `
    },
    {
        id: 3,
        title: 'SECTION 3: Launch Pad',
        body: `
            Hard skills generally have rules that remain the same regardless of the business, industry, or even culture in which they are practiced.
            <br>
            <br>
            Hard skills are abilities that let you tackle job-specific duties and responsibilities. In this section, you will learn all the hard skills that are necessary to become a content creator.
            <br>
            <ul>
                <br>
                <li>How to get Started</li>
                <li>Content Planning and Scheduling</li>
                <li>Titles, Tags & Description</li>
                <li>More about Titles</li>
                <li>Thumbnail</li>
                <li>How to get Initial 1000 Subscribers</li>
                <li>Creating Good Community</li>
                <li>YouTube Algorithm</li>
                <li>YouTube Formats</li>
                <li>Ways of Monetization</li>
                <li>Daily Reporting</li>
                <li>15 Quick Steps CC</li>
                <li>How to Become a Productive Creator</li>
            </ul>
        `
    },
    {
        id: 4,
        title: 'SECTION 4: Fly High',
        body: `
            Only skills will not take you ahead in life, you need to be disciplined enough to take creative feedback from people. Your consistency and hard work will take you ahead and you will start growing in life. In this section, you will learn how to start money as a content creator. At the initial stage, you will learn to create your own personal brand.
            <br>
            <ul>
                <br>
                <li>5 Ways to Increase Production Quality</li>
                <li>Ways to Earn Money as a Content Creator</li>
                <li>Earn Money as Freelancer</li>
                <li>Build Your Portfolio</li>
                <li>Leverage</li>
                <li>Freelancing vs Hiring Team</li>
                <li>Hiring</li>
                <li>Partnership</li>
                <li>Owning Your Audience</li>
                <li>Personal Branding</li>
                <li>Podcast</li>
                <li>Power Selling</li>
                <li>Negotiation Skills</li>
                <li>Payment Handling Issues</li>
                <li>Branding vs Marketing</li>
            </ul>
        `
    },
    {
        id: 5,
        title: 'SECTION 5: Case Studies',
        body: `
            If you want to become a successful person, you need to start thinking like a successful person. In this section, you will find 15 case studies of dominant personalities. Successful people don’t do different things, they do things differently. Your mindset is your real asset.
            <br>
            <ul>
                <br>
                <li>Vivek Bindra</li>
                <li>Arvind Arora</li>
                <li>Beyounick</li>
                <li>Sandeep Maheshwari</li>
                <li>Ranveer Allahbadia</li>
                <li>Gaurav Taneja</li>
                <li>Prajakta Kohli</li>
            </ul>
        `
    },
    {
        id: 6,
        title: 'SECTION 6: Content Writing',
        body: `
            Content writing helps you in building a relationship with your audience and interest them in coming back for more. The power of well-written content should never be underestimated because it can make a huge difference in your overall campaign. In this section, you will learn everything about content creation.
            <br>
            <ul>
                <br>
                <li>Introduction to Content Writing</li>
                <li>Open Loops</li>
                <li>Asking Right Questions</li>
                <li>How to do Content Research?</li>
                <li>Novelty</li>
                <li>Imagination</li>
                <li>Anecdote</li>
                <li>Energy</li>
                <li>Story Telling</li>
                <li>AIDA Model</li>
                <li>Framing</li>
                <li>Ending Part</li>
                <li>End Screen</li>
            </ul>
        `
    },
];

export default AccordContent;
