//React, React Router, Formik
import React from 'react';
import Link from 'next/link';
import {CONTACT} from "@helper/constants/routes";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        color: '#FFF',
        padding: theme.spacing(7.5, 3.75),
        "& > div": {
            padding: theme.spacing(0, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before": {
                content: '"\\1F48C"'
            }
        },
    },
    body: {
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(10),
            },
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        },
        "& a": {
            color: '#FFF',
            textDecoration: 'underline'
        }
    },
}));

const ChallengesOfYoutuber = () => {

    const classes = useStyles();

    const {appMeta} = useSelector(state => state.siteDetail);

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    Need a Hand? Drop us an Email
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>

                <Typography>
                    If you&apos;ve got any questions about this academy, or need a hand with anything else, we&apos;re just an email
                    away. Drop us a line at {appMeta?.contactEmail} or <Link href={CONTACT.show}><a>Contact Here</a></Link> and we&apos;ll do our best to help :)
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ChallengesOfYoutuber;
