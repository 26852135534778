//React, React Router, Formik
import React, {useEffect, useRef, useState} from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DOMPurify from "dompurify";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    accordionRoot: {
        boxShadow: 'none',
        background: theme.palette.landingPage.body4,
        marginBottom: theme.spacing(1.25),
        paddingRight: theme.spacing(18.5),
        "&::before": {
            all: 'unset'
        }
    },
    accordionRounded: {
        borderRadius: [39, '!important'],
    },
    accordionRootExpanded: {
        background: '#FFF',
        boxShadow: '0 6px 20px rgb(0 0 0 / 12%), 0 2px 3px rgb(0 0 0 / 6%)',
        borderRadius: [8, '!important'],
        margin: [theme.spacing(4, 0), '!important'],
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        }
    },
    accordionSummaryRoot: {
        padding: theme.spacing(3.75),
    },
    accordionSummaryContent: {
        order: 12,
        margin: 0,
        "& .MuiTypography-root": {
            padding: 0,
            marginBottom: [0, '!important'],
            fontSize: 18,
            lineHeight: '20px'
        }
    },
    accordionSummaryContentExpanded: {
        margin: [0, '!important'],
        transform: ['none', '!important']
    },
    expandIcon: {
        padding: theme.spacing(0, 7, 0, 0)
    },
    heading: {
        fontWeight: 'bold'
    },
    accordionDetailRoot: {
        padding: theme.spacing(0, 19.5, 5.25),
        "& .MuiTypography-root": {
            padding: 0,
            marginBottom: [0, '!important'],
            fontSize: 16,
            lineHeight: '26px',
            color: theme.palette.landingPage.font3
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 5, 8),
        }
    }
}));

const AccordPanel = ({row, defaultExpand}) => {

    const classes = useStyles();

    const [expanded, setExpanded] = useState(defaultExpand);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion
                defaultExpanded={defaultExpand}
                onChange={() => handleChange(`panel-${row.id}`)}
                classes={{
                    root: classes.accordionRoot,
                    rounded: classes.accordionRounded,
                    expanded: classes.accordionRootExpanded
                }}
            >
                <AccordionSummary
                    expanded={expanded === `panel-${row.id}`}
                    expandIcon={expanded === `panel-${row.id}` ? <RemoveIcon/> : <AddIcon/>}
                    classes={{
                        root: classes.accordionSummaryRoot,
                        content: classes.accordionSummaryContent,
                        expandIcon: classes.expandIcon,
                        expanded: classes.accordionSummaryContentExpanded
                    }}
                >
                    <Typography className={classes.heading}>
                        {row.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    classes={{
                        root: classes.accordionDetailRoot
                    }}
                >
                    <Typography>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(row.body)}}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default AccordPanel;
