//React, React Router, Formik
import React from 'react';
import CustomImage from "@util/CustomImage/CustomImage";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.heading0,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            ...theme.typography.landingPage.title2,
        }
    },
    body: {
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            flexWrap: 'nowrap',
            alignItems: 'center',
        }
    },
    imageWrapper: {
        width: '100%',
        "& > span": {
            position: 'unset !important'
        },
        "& .custom-image": {
            objectFit: 'contain',
            width: '100% !important',
            height: '100% !important',
            position: 'relative !important',
        },
    },
    firstImage: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(3),
        paddingBottom: [theme.spacing(6.25), '!important'],
        "& .custom-image": {
            //height: [87, '!important'],
            height: [70, '!important']
        }
    },
    secondImage: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: [theme.spacing(4), '!important'],
        "& .custom-image": {
            height: [130, '!important'],
        }
    },
    thirdImage: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: [theme.spacing(4.75), '!important'],
        "& .custom-image": {
            //height: [95, '!important'],
            height: [87, '!important']
        }
    },
    fourthImage: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(6),
        paddingBottom: [theme.spacing(4), '!important'],
        "& .custom-image": {
            //height: [40, '!important'],
            height: [65, '!important'],
        }
    }
}));

const ExclusiveBenefit = () => {

    const classes = useStyles();

    const assetPath = useSelector(state => state.siteDetail.assetPath);

    return (
        <Grid id="exclusive" container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    Exclusive Discounts & Benefits
                </Typography>
            </Grid>

            <Grid item container xs={12} className={classes.body}>
                <Grid item xs={12} md={4} className={`${classes.imageWrapper} ${classes.firstImage}`}>
                    <CustomImage
                        loaded={!!assetPath}
                        src={`${assetPath}ovb-logo-dark.svg`}
                        alt="Image"
                        layout="fill"
                    />
                </Grid>

                <Grid item xs={12} md={4} className={`${classes.imageWrapper} ${classes.secondImage}`}>
                    <CustomImage
                        loaded={!!assetPath}
                        src={`${assetPath}passion-black-logo.png`}
                        alt="Image"
                        layout="fill"
                    />
                </Grid>

                {/*<Grid item xs={12} md={4} className={`${classes.imageWrapper} ${classes.secondImage}`}>
                    <CustomImage
                        loaded={!!assetPath}
                        src={`${assetPath}tubebuddy-logo.png`}
                        alt="Image"
                        layout="fill"
                    />
                </Grid>*/}

                <Grid item xs={12} md={4} className={`${classes.imageWrapper} ${classes.thirdImage}`}>
                    <CustomImage
                        loaded={!!assetPath}
                        src={`${assetPath}notion-logo.png`}
                        alt="Image"
                        layout="fill"
                    />
                </Grid>

                {/*<Grid item xs={12} md={4} className={`${classes.imageWrapper} ${classes.fourthImage}`}>
                    <CustomImage
                        loaded={!!assetPath}
                        src={`${assetPath}epidemic-sound-logo.png`}
                        alt="Image"
                        layout="fill"
                    />
                </Grid>*/}
            </Grid>
        </Grid>
    );
};

export default ExclusiveBenefit;
