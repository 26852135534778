//React, React Router, Formik
import React, {useRef} from 'react';
import CustomImage from "@util/CustomImage/CustomImage";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import CourseContentCard from "@ui/LandingPage/CourseContentCard/CourseContentCard";
import {useSelector} from "react-redux";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        [theme.breakpoints.up('')]: {
            padding: theme.spacing(4, 0),
        },
        "& > div": {
            padding: theme.spacing(4, 3.75),
            [theme.breakpoints.up('')]: {
                padding: theme.spacing(4, 0),
            },
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    body: {
        color: theme.palette.landingPage.font2,
        '& li': {
            ...theme.typography.landingPage.body,
            lineHeight: '35px',
        },
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            paddingLeft: theme.spacing(10),
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
    cardWrapper: {
        "&:first-child": {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 3, 0, 6)
            }
        },
        "&:last-child": {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 6, 0, 3)
            }
        },
    },
}));

const ExtraPerks = () => {

    const classes = useStyles();

    const {assetPath} = useSelector(state => state.siteDetail);

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    Extra Perks
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>

                <Typography>
                    Here are a few extra bits we&apos;ve put together to make sure you&apos;re in the best position to succeed and feel supported in the future too:
                </Typography>
            </Grid>

            <Grid item container xs={12}>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://static.dbc.academy/abed1e5658b3ad23c38c22646968e4f2/files/media/images/2022/10/ad459304-661f-444e-bec1-2263cb0ea8a6/conversions/3515798da80aa153bd3c6a9513c20c64-1280x720.webp`}
                        title="Video Editing with Adobe Premier Pro"
                        body="In this class, you'll learn everything you need to get started with Premier Pro and take your editing to the next level. We'll work to create a YouTube-style video that will upload on our channels."
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://static.dbc.academy/abed1e5658b3ad23c38c22646968e4f2/files/media/images/2022/10/edef733f-71f1-4868-9b94-b8308cf92916/conversions/afe4abad9308c1b9e1dfff7c5874b963-1280x720.webp`}
                        title="Passion Course"
                        body="Find your passion helps us to discover yourself and about the things you are good at. 25 questions which you can ask yourself to find your passion. Henri Junttila managed to share all this useful information in such a concise book. He also provided us with additional insightful questions and a complete workbook, and the book cannot be read in its entirety without paper and pen."
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`${assetPath}dbc-community.webp`}
                        title="Lifetime Access to the DBC Community"
                        body="You will get lifetime access to our PPG (Private Premium Group) on Telegram where like-minded people can brainstorm new ideas, and share strategies that are working for them."
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://static.dbc.academy/abed1e5658b3ad23c38c22646968e4f2/files/media/images/2022/10/c452ead0-f27c-4068-bae7-67e8b308f7ab/conversions/52ae2a8b2eac9ec75951a3ba7598a96f-1280x720.webp`}
                        title="DBC-365 Workbook"
                        body="When you enroll in the program, you'll get access to our digital workbook. This will act as a supplementary guide throughout the program with each lesson broken down into exercises taken from the lessons that you can use to really make the most of the teaching and supercharge your growth."
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://dbc.academy/_next/image?url=https%3A%2F%2Fstatic.onlinevideobooks.com%2Fabed1e5658b3ad23c38c22646968e4f2%2Ffiles%2Fmedia%2Fimages%2F2022%2F04%2F75dc8a0f-87d8-4f4e-a38b-ed7896eec393%2Fconversions%2Ff9fe3085a615424ba5b8505cb65d19b4-1280x720.webp&w=1920&q=75`}
                        title="Building a StoryBrand VideoBook"
                        body="Even if we've got the simplest product within the marketplace, building a story brand helps you sell more . People should relate more with your story instead of brand. If we haven’t identified what our customer wants, what problem we are helping them solve, and what life will seem like after they engage our products and services we are able to chuck thriving within the marketplace."
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://dbc.academy/_next/image?url=https%3A%2F%2Fstatic.onlinevideobooks.com%2Fabed1e5658b3ad23c38c22646968e4f2%2Ffiles%2Fmedia%2Fimages%2F2022%2F06%2Fa225a667-1ad3-43f0-ad80-cdfd2cc5d328%2Fconversions%2F85e41788d0717511ffc65eaacb6df441-1280x720.webp&w=1920&q=75`}
                        title="Contagious VideoBook"
                        body="Contagious Video book will explain to you, why few things catch up and how your content on social media can get the highest reach. Most people believe that products and concepts become successful because of 3 factors: quality, price, and advertising. Although these factors are relevant, they don’t tell the total story, e.g. a free, low-quality YouTube video can still go viral with no advertising."
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://dbc.academy/_next/image?url=https%3A%2F%2Fstatic.onlinevideobooks.com%2Fabed1e5658b3ad23c38c22646968e4f2%2Ffiles%2Fmedia%2Fimages%2F2022%2F07%2F1bcc3d9d-d206-42f3-a50a-62e4b41bd7e8%2Fconversions%2F2c02a26704024e9d9f001f842e896555-1280x720.webp&w=1920&q=75`}
                        title="Made to Stick VideoBook"
                        body="Made to Stick video book clearly explains why some ideas survive and others die so quickly. The main problem is the curse of knowledge. The person sharing the idea has all sorts of inside information that others don't, so they already outline the problem and understand its relevance."
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`https://dbc.academy/_next/image?url=https%3A%2F%2Fstatic.onlinevideobooks.com%2Fabed1e5658b3ad23c38c22646968e4f2%2Ffiles%2Fmedia%2Fimages%2F2022%2F09%2Fb93771e9-8377-43fc-bb52-5cdf15d0a446%2Fconversions%2F405d95dfb6cdf24e49a8a83d9b3839af-1280x720.webp&w=1920&q=75`}
                        title="The 22 Immutable Laws of Marketing"
                        body="Learn 22 marketing principles that you can apply to any business. Discover why perceptions are more crucial than products, and how to shape customers’ perceptions. Find out how to position and brand your business, to be at the top of your customer's minds. Uncover the right approach to counter an existing market leader and what it means to design your marketing strategy to be #1 or #2 in the market for long-term success."
                        width={500}
                        height="auto"
                    />
                </Grid>

                {/***************************************************************/}

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`${assetPath}thumbnail.jpg`}
                        title="Done for you - Thumbnail"
                        body="You put so much effort into your YouTube channel and each video you upload, yet you don’t seem to get the recognition and watch time you desire.
According to YouTube, 90% of best-performing videos have custom thumbnails. We have made some best thumbnail templates that would work for your videos"
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`${assetPath}transition-pack.jpg`}
                        title="Done for you - Transition Pack"
                        body="Transitions can take many different forms, from straight cuts to fades and dissolves to full-blown 3D animations. Before you start using transitions in your projects, you should learn some video transition basics. We have made some best transition packs that you can use in your videos"
                        width={500}
                        height="auto"
                    />
                </Grid>

                <Grid item xs={12} md={6} className={classes.cardWrapper}>
                    <CourseContentCard
                        imageSrc={`${assetPath}sound-effects.jpg`}
                        title="Done for you - SFX(sound effects)"
                        body="While editing a video presentation, you can insert simple sounds like a glass clink, boing, jump, or mouse click with each new bullet point.  You can easily add an effect while editing your video. We have made “Done for you SFX” which you can use in your videos"
                        width={500}
                        height="auto"
                    />
                </Grid>

            </Grid>
        </Grid>
    );
};

export default ExtraPerks;
