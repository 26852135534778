//React, React Router, Formik
import React from 'react';
import CustomImage from "@util/CustomImage/CustomImage";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper1: {
        padding: theme.spacing(20, 3.75)
    },
    wrapper2: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(3.75),
            paddingRight: theme.spacing(3.75)
        }
    },
    wrapper3: {
        paddingBlock: theme.spacing(7.5)
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(3.75),
            paddingRight: theme.spacing(3.75)
        }
    },
    title: {
        color: '#FFF',
        ...theme.typography.landingPage.title3,
        marginBottom: theme.spacing(2),
        "& span": {
            "&::before": {
                content: '"\\1F31F"'
            }
        }
    },
    body: {
        padding: theme.spacing(4.5, 7.5, 0),
        color: theme.palette.landingPage.body2,
        '& li': {
            ...theme.typography.landingPage.body,
            lineHeight: '35px',
        },
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
}));

const MoneyBackPromise = () => {

    const classes = useStyles();

    return (
        <div className={classes.wrapper1}>
            <div className={classes.wrapper2}>
                <div className={classes.wrapper3}>
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                <strong><span/></strong> 100% Satisfaction Guarantee - Money-Back Promise
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.body}>

                            <Typography>
                                We want the investment in this program to be an absolute no-brainer for you. So, if you
                                watch the video lessons, submit a video for feedback each week, and still don&apos;t find the
                                program useful for 30 days, then drop us an email within 30 days to get a refund.
                            </Typography>

                            <Typography>
                                However, please note <b>that the money-back guarantee only applies if you actually do the
                                work and complete the assignments.</b> If you don&apos;t do the work, you won&apos;t get any value
                                from the program, and you&apos;ll have taken a place away from someone else.
                            </Typography>

                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default MoneyBackPromise;
