//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import {Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(12.5),
    },
    pagination: {
        marginTop: theme.spacing(6.25),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(6.25),
        }
    },
    button: {
        cursor: 'pointer',
        width: 62,
        height: 62,
        padding: theme.spacing(2.5),
        borderRadius: '50%',
        position: 'relative',
        "&:not(:last-of-type)": {
            marginRight: theme.spacing(7.5),
        },
        "& .MuiAvatar-root": {}
    },
    activeButton: {
        border: `1px ${theme.palette.landingPage.secondary} solid`,
        "&::before": {
            content: '""',
            position: 'absolute',
            right: 0,
            left: 0,
            width: 1,
            margin: 'auto',
            backgroundColor: theme.palette.landingPage.secondary,
            height: 45,
            bottom: '70%'
        },
    },
    btnContent: {
        position: 'absolute'
    },
    footer: {
        position: 'absolute',
        width: 172,
        left: -57,
        right: -57,
        top: '100%',
        marginTop: theme.spacing(6.25),
        "& .MuiTypography-root": {
            marginBottom: 0,
            textAlign: 'center'
        }
    },
    name: {
        fontSize: 20,
        lineHeight: '21px',
        color: props => props.background === 'dark' ? theme.palette.landingPage.body2 : theme.palette.landingPage.font4,
    },
    profession: {
        fontSize: 15,
        lineHeight: '21px',
        color: props => props.background === 'dark' ? theme.palette.landingPage.body2 : theme.palette.landingPage.font4,
    }
}));

const CarouselButton = ({ next, previous, goToSlide, testimonialContent, ...props }) => {

    const classes = useStyles(props);

    const { carouselState: { currentSlide } } = props;

    return (
        <div className={classes.root}>
            <Grid container justifyContent="center" className={classes.pagination}>

                {
                    testimonialContent.map((item, index) => (
                        <Grid
                            key={item.id}
                            item
                            className={`${classes.button} ${currentSlide === index ? classes.activeButton : ''}`}
                            onClick={() => goToSlide(index)}
                        >
                            <Grid container direction="column" className={classes.btnContent}>
                                <Grid item>
                                    <Avatar src={item.avatar}/>
                                </Grid>
                                {
                                    currentSlide === index &&
                                    <Grid item className={classes.footer}>
                                        <Typography className={classes.name}>
                                            {item.name}
                                        </Typography>
                                        <Typography className={classes.profession}>
                                            {item.profession}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    ))
                }

                {/*<Grid item className={classes.button} onClick={() => goToSlide(0)}>
                    <Grid container direction="column" className={classes.btnContent}>
                        <Grid item>
                            <Avatar
                                src="https://samcart-foundation-prod.s3.amazonaws.com/marketplace-84349/assets/df8ad435-02c1-4d1c-903d-1ba3a2ae5914"
                            />
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Typography>
                                John Coogan
                            </Typography>
                            <Typography>
                                Entrepreneur, YouTuber
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className={classes.button} onClick={() => goToSlide(1)}>
                    <Grid container direction="column" className={classes.btnContent}>
                        <Grid item>
                            <Avatar
                                src="https://samcart-foundation-prod.s3.amazonaws.com/marketplace-84349/assets/cec02e85-8d36-47b2-90a2-b9d7b872b568"
                            />
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Typography>
                                Clara Carmona
                            </Typography>
                            <Typography>
                                Med student, YouTuber
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className={classes.button} onClick={() => goToSlide(2)}>
                    <Grid container direction="column" className={classes.btnContent}>
                        <Grid item>
                            <Avatar
                                src="https://samcart-foundation-prod.s3.amazonaws.com/marketplace-84349/assets/434c6668-3562-4d52-999d-88d415db35d6"
                            />
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Typography>
                                Matt Brighton
                            </Typography>
                            <Typography>
                                Property investor, YouTuber
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>*/}
            </Grid>
        </div>
    );
};

export default CarouselButton;
