//React, React Router, Formik
import React from 'react';
import CustomImage from "@util/CustomImage/CustomImage";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        "& span": {
            "&::before":  {
                content: '"\\1F5D3"'
            }
        }
    },
    body: {
        color: theme.palette.landingPage.font2,
        '& li': {
            ...theme.typography.landingPage.body,
            lineHeight: '35px',
        },
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body3,
            paddingLeft: theme.spacing(10),
            "&:not(last-child)": {
                marginBottom: theme.spacing(4)
            }
        }
    },
    imageWrapper: {
        width: '100%',
        "& > span": {
            position: 'unset !important'
        },
        "& .custom-image": {
            objectFit: 'contain',
            width: '100% !important',
            height: '100% !important',
            position: 'relative !important',
        },
    }
}));

const TimeTable = () => {

    const classes = useStyles();

    const {assetPath} = useSelector(state => state.siteDetail);

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    <strong><span /></strong> The Monthly Timetable
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.body}>

                <Typography>
                    You definitely aren&apos;t expected to be able to attend every single live session, and every session is <b>recorded and you&apos;ll have *lifetime access</b> to everything.
                </Typography>

                <Typography>
                    Here’s an example of what a month in the DBC Academy looks like:
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.imageWrapper}>
                <CustomImage
                    loaded={true}
                    src={`${assetPath}timetable.webp`}
                    alt="Image"
                    layout="fill"
                    //style={{objectFit: 'contain'}}
                />
            </Grid>
        </Grid>
    );
};

export default TimeTable;
