//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import CustomImage from "@util/CustomImage/CustomImage";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        color: '#FFF',
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    container: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 18)
        }
    },
    avatarWrapper: {
        marginBottom: theme.spacing(5.75),
        "& .MuiAvatar-root": {
            width: 48,
            height: 48,
            [theme.breakpoints.up('sm')]: {
                width: 100,
                height: 100,
            }
        }
    },
    nameWrapper: {
        marginBottom: theme.spacing(4.25),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(6),
        }
    },
    contentWrapper: {
        marginBottom: theme.spacing(7),
        "& .MuiTypography-root": {
            fontSize: 22,
            lineHeight: '30px',
            fontStyle: 'italic',
            [theme.breakpoints.up('sm')]: {
                fontSize: 24,
                lineHeight: '30px',
            }
        }
    }

}));

const Testimonial3 = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>

            <Grid item xs={12}>
                <Grid container direction="column" alignItems="center" className={classes.container}>
                    <Grid item className={classes.avatarWrapper}>
                        <Avatar alt=""
                                src="https://samcart-foundation-prod.s3.amazonaws.com/marketplace-84349/assets/5f8fc280-8ea2-43c2-8245-9c58bdd8c30a"
                        />
                    </Grid>
                    <Grid item className={classes.nameWrapper}>
                        <Typography>
                            SHERVIN SHARES
                        </Typography>
                    </Grid>

                    <Grid item className={classes.contentWrapper}>
                        <Typography align="center">
                            I was hesitant because of the cost but having access to Ali was game-changing. This is the only
                            course on YouTube where you can meet like-minded folks who will keep you accountable for posting
                            videos & improving your skills.
                        </Typography>
                    </Grid>

                    <Grid item>
                        <CustomImage
                            loaded={true}
                            src="https://template-builder-assets.s3-us-west-2.amazonaws.com/images/widget-testimonials-type-8-quotes.svg"
                            alt="Image"
                            width={38}
                            height={32}
                        />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default Testimonial3;
