//React, React Router, Formik
import React from 'react';
import CustomImage from "@util/CustomImage/CustomImage";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    title: {
        ...theme.typography.landingPage.title,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    body: {
        ...theme.typography.landingPage.body3,
        color: theme.palette.landingPage.font2,
        "&:not(last-child)": {
            marginBottom: theme.spacing(4)
        }
    },
}));

const SecretSubscribers = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    ⚗️The Secret to 100,000+ Followers
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.body}>
                    Over the last 7 years, I’ve learned a lot about what it takes <b>to build an audience from scratch,
                    provide value consistently, and monetize in a non-spammy way.</b>
                </Typography>

                <Typography className={classes.body}>
                    I’ve spent over ₹800,000 and 100s of Hours in courses, content, and coaching programs to try and
                    find the secret sauce that helps grow a channel and a business.
                </Typography>

                <Typography className={classes.body}>
                    And you know what? There’s no secret. It’s just a matter of:
                </Typography>

                <Typography className={classes.body}>
                    <b>1. Creating content your target audience finds useful</b>
                </Typography>

                <Typography className={classes.body}>
                    <b>2. Posting it on YouTube/Instagram/Facebook at least once a week for a few years.</b>
                </Typography>

                <Typography className={classes.body}>
                    <b>3. Keep experimenting and staying ahead of changing trends.</b>
                </Typography>

                <Typography className={classes.body}>
                    That’s all it takes.
                </Typography>

                <Typography className={classes.body}>
                    I personally guarantee that if you follow this 3-part formula, your life will change in ways you
                    can&apos;t imagine.
                </Typography>

                <Typography className={classes.body}>
                    For starters, you&apos;ll learn incredibly useful skills, you&apos;ll generate ‘passive&apos; income, and you&apos;ll
                    make friends with amazing people from all over the country and even the world. You might even get
                    messages from people about how your content&apos;s changed their life.
                </Typography>

                <Typography className={classes.body}>
                    But as with all things, getting started and maintaining quality + consistency is easier said than
                    done.
                </Typography>


            </Grid>
        </Grid>
    );
};

export default SecretSubscribers;
