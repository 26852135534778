//React, React Router, Formik
import React from 'react';

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        maxWidth: 1170,
        padding: theme.spacing(1, 0, 4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 3.75),
        },
        "& > div": {
            padding: theme.spacing(4, 3.75),
        }
    },
    videoContainer: {
        "& > div": {
            position: 'relative',
            paddingBottom: '56.25% !important',
            height: 0,
            overflow: 'hidden',
            width: '100%',
            "& iframe": {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
            },
        },
        "&:first-child": {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 3, 0, 6)
            }
        },
        "&:last-child": {
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 6, 0, 3)
            }
        }
    }
}));

const VideoMarketing = () => {

    const classes = useStyles();

    return (

        <Grid container className={classes.wrapper}>
            <Grid item xs={12} md={6} className={classes.videoContainer}>
                <div>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/644036051?h=b260a3b1b0" frameBorder="0" allowFullScreen></iframe>
                </div>
            </Grid>
            <Grid item xs={12} md={6} className={classes.videoContainer}>
                <div>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/644036051?h=b260a3b1b0" frameBorder="0" allowFullScreen></iframe>
                </div>
            </Grid>
        </Grid>
    );
};

export default VideoMarketing;
