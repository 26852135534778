//React, React Router, Formik
import React from 'react';
import TestimonialItem from "@ui/LandingPage/Testimonial1/TestimonialItem/TestimonialItem";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Carousel from "react-multi-carousel";
import CarouselButton from "@ui/LandingPage/Testimonial1/CarouselButton/CarouselButton";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    carousel: {
        "& .react-multi-carousel-track": {
            alignItems: 'flex-end'
        }
    },
}));

const Testimonial1 = ({testimonialContent, background}) => {

    const classes = useStyles();

    const responsive = {
        mobile: {
            breakpoint: {max: 4000, min: 0},
            items: 1,
            slidesToSlide: 1,
        }
    };

    const ButtonGroup = (props) => {
        return (
            <div className="carousel-button-group" >
                <CarouselButton {...props} testimonialContent={testimonialContent} background={background}/>
            </div>
        );
    };

    return (
        <div className='my-own-custom-container'>
            <Carousel
                className={classes.carousel}
                responsive={responsive}
                arrows={false}
                shouldResetAutoplay={false}
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside={true}
            >
                {
                    testimonialContent.map(item => (
                        <TestimonialItem key={item.id} testimonial={item} background={background}/>
                    ))
                }
            </Carousel>

        </div>
    );
};

export default Testimonial1;
