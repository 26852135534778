//React, React Router, Formik
import React, {useRef} from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

//Redux

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 3.75),
        marginTop: theme.spacing(7.5),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    contentWrapper: {
        "& .MuiTypography-root": {
            ...theme.typography.landingPage.body2,
            color: theme.palette.landingPage.font4
        }
    },
    nameWrapper: {
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5)
        },
        "& .MuiTypography-root": {
            fontSize: [23, '!important'],
            lineHeight: ['28px', '!important'],
            fontWeight: 'bold',
            color: theme.palette.landingPage.font4
        },
    },
    professionWrapper: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5)
        },
        "& .MuiTypography-root": {
            fontSize: 17,
            lineHeight: '32px',
            color: theme.palette.landingPage.font4
        },
    }
}));

const responsive = {
    mobile: {
        breakpoint: {max: 4000, min: 0},
        items: 1,
        slidesToSlide: 1,
    }
};

const ImageCarousel = () => {

    const classes = useStyles();

    let items = [
        {
            id: 1,
            content: 'Charlie is the co-founder of Charisma on Command - a channel with nearly 5 million subscribers. Charlie will be running TWO workshops during the course focussed on his YouTube systems and how he\'s built a business from his channel.',
            name: 'Charlie Houpert',
            profession: 'Co-Founder of Charisma on Command'
        },
        {
            id: 2,
            content: 'Pat is the founder of the SmartPassiveIncome website and he\'s returning to PTYA after giving a fantastic interview during the last cohort. He\'ll be looking at monetisation and how he\'s adapted and grown his online businesses over the past decade.',
            name: 'Pat Flynn',
            profession: 'Founder of SmartPassiveIncome'
        },
        {
            id: 3,
            content: 'Thomas Frank is a YouTuber, entrepreneur and author. In 2010, he started his blog, College Info Geek, to share his experiences and experiments with studying but, since then, he\'s developed his brand increasingly around productivity and self-development on YouTube and has now amassed over 120 million views and 2 million subscribers.',
            name: 'Thomas Frank',
            profession: '2M+ YouTuber, Notion Expert',
        },
    ];

    return (
        <div>
            <Carousel
                className={classes.carousel}
                responsive={responsive}
                arrows={false}
                autoPlay={true}
                infinite={true}
            >
                {
                    items.map((item, index) => (
                        <Grid key={item.id} container direction="column" className={classes.root}>
                            <Grid item className={classes.contentWrapper}>
                                <Typography>
                                    {item.content}
                                </Typography>
                            </Grid>

                            <Grid item className={classes.nameWrapper}>
                                <Typography>
                                    {item.name}
                                </Typography>
                            </Grid>

                            <Grid item className={classes.professionWrapper}>
                                <Typography>
                                    {item.profession}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))
                }

            </Carousel>
        </div>
    );
};

export default ImageCarousel;
