//React, React Router, Formik
import React, {useState} from 'react';
import {useRouter} from "next/router";
import ProfileMenu from "@ui/ProfileMenu/ProfileMenu";
import AuthModal from "@ui/AuthModal/AuthModal";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid} from "@material-ui/core";

//Redux
import {useDispatch, useSelector} from "react-redux";
import {logout} from "@store/Auth/actions";

//Assets

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(4, 3.75),
        "& > div": {
            padding: theme.spacing(4, 0),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(4, 3.75),
            },
            "& img": {
                height: 50,
            }
        }
    },
    authButton: {
        color: theme.palette.landingPage.secondary,
        "& .MuiButton-label": {
            ...theme.typography.landingPage.body2
        },
        "&:hover": {
            textDecoration: 'none'
        }
    }
}));

const Header = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();

    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('signIn');
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const changeModalMode = (mode) => {
        setModalMode(mode);
    }

    const handleAuthModalOpen = (mode) => {
        setAuthModalOpen(true);
        changeModalMode(mode);
    };

    const handleAuthModalClose = () => {
        setAuthModalOpen(false);
    };

    const handleLogout = () => {
        dispatch(logout(router));
    }

    const brandingLogo = useSelector(state => state.siteDetail.brandingLogo);

    return (
        <>
            {/*Auth Modal*/}
            {
                !isAuthenticated && authModalOpen &&
                <AuthModal
                    open={authModalOpen}
                    closeHandler={handleAuthModalClose}
                    modalMode={modalMode}
                    modalModeHandler={changeModalMode}
                />
            }

            <Grid container className={classes.wrapper} justifyContent="space-between" alignItems="center">
                <Grid item>
                    {/*Logo*/}
                    <div>
                        <img
                            src={brandingLogo ? brandingLogo.url.replace(/\\/g, "/") : ''}
                            alt=""/>
                    </div>
                </Grid>
                <Grid item>
                    {
                        !isAuthenticated ?
                            <Button variant="text" className={classes.authButton}
                                    onClick={() => handleAuthModalOpen('signUp')}
                            >
                                Login
                            </Button> :
                            <ProfileMenu />
                            /*<Button variant="text" className={classes.authButton}
                                    onClick={handleLogout}
                            >
                                Logout
                            </Button>*/
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default Header;
